import { AfterViewInit, Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras, NavigationEnd } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CssService } from '../../services/css.service';

@Component({
  selector: 'guys-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements AfterViewInit {

  expandMenu: boolean = false;
  // url = new BehaviorSubject<string>('');
  url: string | undefined;
  snappable = false;
  @HostBinding('style.scroll-snap-align') public snapAlign = "unset";

  options: AnimationOptions = {
    path: '../../../assets/animations/menu-legacy.json',
  };

  options2: AnimationOptions = {
    path: '../../../assets/animations/shapes.json',
  };

  logoSideOptions: AnimationOptions = {
    path: '../../../assets/animations/logo-side.json',
  };

  constructor(private router: Router, private route: ActivatedRoute, private cssService: CssService) {

  }

  ngAfterViewInit(): void {
    this.router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {
        this.url = this.router.url;
        const snapAlignEnd = (/(animation$)|(design$)|(home$)/g).test(this.url);
        const scrollTypeOverlay = (/(animation$)|(design$)/g).test(this.url);
        this.snapAlign = snapAlignEnd ? "end" : "unset";
        this.cssService.setScrollTypeOverlay(scrollTypeOverlay);
      }
    })
  }

  navigateWork(type: string): string {
    let destinationPrefix = this.url?.includes("admin") ? "admin/work/" : "work/";
    return destinationPrefix + type;
  }

}
