import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { IAnimation } from 'src/app/interfaces/animation.interfcae';
import { IDesign } from 'src/app/interfaces/design.interfcae';
import { AnimationService } from 'src/app/services/animation.service';
import { DesignService } from 'src/app/services/design.service';
import { FeaturedService } from 'src/app/services/featured.service';

@Component({
  selector: 'guys-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements AfterViewInit {

  logoOptions: AnimationOptions = {
    path: '../../../assets/animations/mainpage.json',
  };
  redLogoOptions: AnimationOptions = {
    path: '../../../assets/animations/mainpage-red.json',
  };
  redLogo = false;
  featuredDesigns$: Observable<IDesign[]>;
  featuredAnimations$: Observable<IAnimation[]>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private animationService: AnimationService,
    private designService: DesignService,
    private featuredService: FeaturedService,
  ) {
    this.featuredDesigns$ = this.featuredService.getFeatured("designs");
    this.featuredAnimations$ = this.featuredService.getFeatured("animations");
  }

  ngAfterViewInit(): void {
  }

  onMouseEnter(): void {
    document.body.style.setProperty('--primary-background', '#b0273e');
    this.redLogo = true;
  }
  onMouseLeave(): void {
    document.body.style.setProperty('--primary-color', 'white');
    document.body.style.setProperty('--primary-background', 'black');
    this.redLogo = false;
  }

  handleItemClick(item: IDesign | IAnimation): void {
    let type = item.hasOwnProperty('video') ? "animation" : "design";
    if (type === "animation") {
      this.animationService.setSelectedAnimation(item as IAnimation);
    }
    else {
      this.designService.setSelectedDesign(item);
    }
    this.router.navigate([`work/${type}/${item.id}`]);
  }
}
