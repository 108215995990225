<div class="desktop-version">
    <a routerLink=''>
        <!-- <img class="icon" src="../../../assets/images/icon.svg"> -->
        <ng-lottie class="icon" [options]="logoSideOptions"></ng-lottie>
    </a>
    <a class="mock-button" [routerLink]="navigateWork('design')" routerLinkActive="active-link">design</a>
    <a class="mock-button" [routerLink]="navigateWork('animation')" routerLinkActive="active-link">animation</a>
    <a class="mock-button" routerLink='/about' routerLinkActive="active-link">about</a>
    <a class="mock-button" routerLink='/contact' routerLinkActive="active-link">contact</a>
</div>

<div class="mobile-version" [class.expanded]="expandMenu">
    <a (click)="expandMenu = !expandMenu;">
        <ng-lottie class="icon" [class.expanded]="expandMenu" [options]="options"></ng-lottie>
        <!-- <img class="icon" [class.expanded]="expandMenu" src="../../../assets/images/icon.svg"> -->
    </a>

    <ng-container *ngIf="expandMenu">
        <a class="mock-button" routerLink='/home' routerLinkActive="active-link" (click)="expandMenu = false;">home</a>
        <a class="mock-button" routerLink='/about' routerLinkActive="active-link"
            (click)="expandMenu = false;">about</a>
        <a class="mock-button" [routerLink]="navigateWork('design')" routerLinkActive="active-link"
            (click)="expandMenu = false;">design</a>
        <a class="mock-button" [routerLink]="navigateWork('animation')" routerLinkActive="active-link"
            (click)="expandMenu = false;">animation</a>
        <a class="mock-button" routerLink='/contact' routerLinkActive="active-link"
            (click)="expandMenu = false;">contact</a>
        <ng-lottie class="iconAbs" [class.expanded]="expandMenu" [options]="options2"></ng-lottie>
    </ng-container>
</div>