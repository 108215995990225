import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { IAnimation } from 'src/app/interfaces/animation.interfcae';
import { IDesign } from 'src/app/interfaces/design.interfcae';

@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  transform(item: IAnimation | IDesign): SafeUrl {
    let video = (item as IAnimation).video;
    let url = 'https://player.vimeo.com/video'.concat(video.slice(video.lastIndexOf("/")));
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
