<div class="main-container">
    <div class="title">Hi!</div>
    <div class="subtitles-container">
        <div class="subtitle">
            <p class="select">Hi! My name is Guy Shani. I'm a 21-year-old designer currently living in Herzliya, Israel.</p>

            <p class="select"> I'm a motion graphics designer, UI/UX designer, and also a general graphic designer.</p>

            <p class="select"> I believe that design and animation don't necessarily need to be complicated and tangled to create the
                image you want.
                Things shouldn't be complicated in order to be pretty. My design philosophy focuses on simplicity and
                minimalism,
                using clean lines and bold colors to create visually striking and effective designs.
            </p>
            <p class="select">
                In my work, I strive to create designs that are both functional and visually appealing,
                using my skills in motion graphics and UI/UX design to create engaging and user-friendly experiences.
                Whether I'm creating a logo, a website, or an animation, I always aim to deliver high-quality,
                professional designs that effectively communicate my clients' message.
            </p>
            <p class="select"> 
                Please feel free to contact me if you have any questions or opportunities for collaboration.
            </p>
        </div>
        <div class="subtitle profile-container">
            <!-- <ng-lottie class="icon" [options]="options"></ng-lottie> -->
            <div (mouseenter)="restartLiveProfile($event)" class="guy-profile">
                <video #liveProfile loop muted playsinline>
                    <source src="../../../assets/images/guy-profile-live.mp4" type="video/mp4">
                </video>
                <img src="../../../assets/images/guy-profile.jpg">
            </div>
        </div>
    </div>
    <guys-footer></guys-footer>
</div>