import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'guys-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {

  contactForm: FormGroup;
  submitted = false;

  constructor(private httpClient: HttpClient, private fb: FormBuilder) {
    this.contactForm = fb.group({
      name: [''],
      email: ['', [Validators.required, Validators.email]],
      phone_number: ['', Validators.pattern("[0-9 ]{10}")],
      message: ['', Validators.required]
    })
    this.contactForm.valueChanges.subscribe(() => {
      if (this.contactForm.dirty) {
        this.submitted = false;
      }
    });
  }

  ngOnInit(): void {
  }

  submitForm(): void {
    if (this.contactForm.valid) {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      this.httpClient.post(environment.formSpreeUrl, this.contactForm.value, { headers: headers })
        .subscribe(res => {
          this.contactForm.reset();
          this.submitted = true;
        });
    }
  }

}
