<div class="welcome-section">
    <ng-lottie class="icon" [hidden]="!redLogo" [options]="redLogoOptions"></ng-lottie>
    <ng-lottie class="icon" [class.transparent]="redLogo" [options]="logoOptions"></ng-lottie>

    <div class="title">
        Hi! I'm Guy
    </div>
    <div class="subtitle">
        Product Designer & Motion Artist.
    </div>
    <button (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" (click)="onMouseLeave()"
        routerLink="/work/design" class='see-work-btn'>See
        my work</button>
    <div class="scroll-arrows">
        <div class="arrow arrow-first"></div>
        <div class="arrow arrow-second"></div>
        <!-- <div class="scroll-icon-container">
            <span class="scroll-icon-wheel"></span>
        </div> -->
        <!-- <div class="scroll-arrow">

        </div> -->
    </div>
</div>

<div class="items-container">
    <guys-item [featured]="true" *ngFor="let item of featuredDesigns$ | async" [item]="item"
        (itemClickedEmitter)="handleItemClick($event)">
    </guys-item>
    <guys-item [featured]="true" *ngFor="let item of featuredAnimations$ | async" [item]="item"
        (itemClickedEmitter)="handleItemClick($event)">
    </guys-item>
    <button routerLink="/work/design" class='see-work-btn featured-btn'>See all</button>
</div>