import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { IAnimation } from '../interfaces/animation.interfcae';

@Injectable({
  providedIn: 'root'
})
export class AnimationService {

  private readonly dbPath = '/animations';

  animationsRef: AngularFirestoreCollection<IAnimation>;

  // mockedAnimations: IAnimation[] = [
  //   {
  //     id: 'asdfasdf',
  //     title: 'Title',
  //     subtitle: 'publishing software like Aldus Page including versions of Lorem',
  //     image: 'https://www.designveloper.com/wp-content/uploads/2020/10/UI-UX-BLOG-BANNER-IMAGE.jpg',
  //     video: 'https://www.youtube.com/embed/BNHjX_08FE0'
  //   },
  //   {
  //     id: 'asdfasdf',
  //     title: 'Title',
  //     subtitle: 'publishing software like Aldus Page including versions of Lorem',
  //     image: 'https://www.designveloper.com/wp-content/uploads/2020/10/UI-UX-BLOG-BANNER-IMAGE.jpg',
  //     video: 'https://www.youtube.com/embed/MF3soDBzB0o'
  //   },
  //   {
  //     id: 'asdfasdf',
  //     title: 'Title',
  //     subtitle: 'publishing software like Aldus Page including versions of Lorem',
  //     image: 'https://www.designveloper.com/wp-content/uploads/2020/10/UI-UX-BLOG-BANNER-IMAGE.jpg',
  //     video: 'https://player.vimeo.com/video/347119375?color=ef2200&byline=0&portrait=0'
  //   },
  //   {
  //     id: 'asdfasdf',
  //     title: 'Title',
  //     subtitle: 'publishing software like Aldus Page including versions of Lorem',
  //     image: 'https://www.designveloper.com/wp-content/uploads/2020/10/UI-UX-BLOG-BANNER-IMAGE.jpg',
  //     video: 'https://www.youtube.com/embed/MF3soDBzB0o'
  //   },
  // ]

  private selectedAnimation$ = new BehaviorSubject<IAnimation | null>(null);

  constructor(private afs: AngularFirestore) {
    this.animationsRef = this.afs.collection(this.dbPath);
  }

  getAnimations(): Observable<IAnimation[]> {
    // return of(this.mockedAnimations);
    return this.animationsRef.valueChanges({ idField: 'id' });
  }

  getSelectedAnimation(): Observable<IAnimation | null> {
    return this.selectedAnimation$.asObservable();
  }

  setSelectedAnimation(animation: IAnimation): void {
    this.selectedAnimation$.next(animation);
  }

  addAnimation(animation: IAnimation) {
    return this.animationsRef.add(animation);
  }

  updateAnimation(animation: IAnimation, id: string | undefined): Promise<void> {
    return this.animationsRef.doc(id).update(animation);
  }

  deleteAnimation(id: string): Promise<void> {
    return this.animationsRef.doc(id).delete();
  }
}


