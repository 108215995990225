<div class="flex">
    <div class="paragraphs mobile">
        <p class="inquiries">
            <span class="subtitle">General Inquiries</span>
            <span>guyshanidesigns@gmail.com</span>
            <span>(+972)-52-468-3503</span>
            <span>@__guyshani__</span>
        </p>
        <p class="followMe">
            <span class="subtitle">Follow Me</span>
            <span><a href="https://www.behance.net/guyshani1" target="_blank">BEHANCE</a></span>
            <span><a href="https://www.instagram.com/__guyshani__/" target="_blank">INSTAGRAM</a></span>
            <span><a href="https://www.facebook.com/Guy-Shani-100686232418220/" target="_blank">FACEBOOK</a></span>
        </p>
    </div>
    <form #form [formGroup]="contactForm" (ngSubmit)="submitForm()" method="POST" class="form" autocomplete="off">

        <div>
            <label>Name</label>
            <input type="text" formControlName="name">
        </div>

        <div>
            <label>Email</label>
            <input type="text" formControlName="email">
        </div>

        <div>
            <label>Phone number</label>
            <input type="text" formControlName="phone_number">
        </div>

        <div>
            <label>Message</label>
            <textarea formControlName="message"></textarea>
        </div>

        <button class="submit-btn" [class.submitted]="submitted" [class.valid]="contactForm.valid">Submit</button>
        <!-- <span class="feedback">Your message has been sent!</span> -->
    </form>
    <span class="line"></span>
    <div class="invitation">
        <h1 class="title">
            Let's work together!
        </h1>
        <div class="paragraphs desktop">
            <p class="inquiries">
                <span class="subtitle">General Inquiries</span>
                <span>guyshanidesigns@gmail.com</span>
                <span>(+972)-52-468-3503</span>
                <span>@__guyshani__</span>
            </p>
            <p class="followMe">
                <span class="subtitle">Follow Me</span>
                <span><a href="https://www.behance.net/guyshani1" target="_blank">BEHANCE</a></span>
                <span><a href="https://www.instagram.com/__guyshani__/" target="_blank">INSTAGRAM</a></span>
                <span><a href="https://www.facebook.com/Guy-Shani-100686232418220/" target="_blank">FACEBOOK</a></span>
            </p>
        </div>
    </div>
</div>
<!-- <guys-footer></guys-footer> -->