import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IAnimation } from 'src/app/interfaces/animation.interfcae';
import { IDesign } from 'src/app/interfaces/design.interfcae';

@Component({
  selector: 'guys-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {

  @Input() item: IDesign | IAnimation | undefined;
  @Input() editable: boolean = false;
  @Input() featured: boolean = false;
  @Output() itemClickedEmitter = new EventEmitter<IDesign | IAnimation>();
  @Output() deleteItemEmitter = new EventEmitter<IDesign | IAnimation>();
  @Output() editItemEmitter = new EventEmitter<IDesign | IAnimation>();
  buttonColors?: {
    text: string[] | undefined;
    background: string[] | undefined;
  }
  buttonTextColor: string | undefined;
  buttonBackgroundColor: string | undefined;
  buttonIsHovered = new BehaviorSubject<boolean>(false);
  active = false;

  constructor(private elementRef: ElementRef) {
    this.elementRef.nativeElement.style.setProperty('--desktopImage', this.item?.thumbnail.desktop);
    this.elementRef.nativeElement.style.setProperty('--mobileImage', this.item?.thumbnail.mobile);
  }

  ngOnInit(): void {
    this.buttonColors = {
      text: this.item?.buttonColor.text.split(", "),
      background: this.item?.buttonColor.background.split(", "),
    }
    this.buttonIsHovered.subscribe(value => {
      this.buttonTextColor = this.buttonColors?.text ? this.buttonColors?.text[value ? 1 : 0] : "";
      this.buttonBackgroundColor = this.buttonColors?.background ? this.buttonColors?.background[value ? 1 : 0] : "";
    });
  }


  itemClicked(event: any): void {
    if (this.featured) {
      const mobileVersion = !!getComputedStyle(this.elementRef.nativeElement).getPropertyValue('--mobile');
      if (!mobileVersion || (mobileVersion && this.active && (event.target.tagName === "BUTTON"))) {
        this.itemClickedEmitter.emit(this.item);
      } else {
        this.active = !this.active;
      }
    } else {
      this.itemClickedEmitter.emit(this.item);
    }
  }

  editItem(event: Event): void {
    event.stopPropagation();
    this.editItemEmitter.emit(this.item);
  }

  deleteItem(event: Event): void {
    event.stopPropagation();
    this.deleteItemEmitter.emit(this.item);

  }
}
