import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'guys-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit {

  @ViewChild("liveProfile") liveProfile: ElementRef | undefined;

  options: AnimationOptions = {

    path: '../../../assets/animations/shapes.json',
  };

  constructor() { }

  ngOnInit(): void {
  }

  restartLiveProfile(event: any): void {
    if (this.liveProfile) {
      let video = this.liveProfile.nativeElement;
      video.currentTime = 0;
      video.muted = true;
      video.play();
      event.currentTarget.style.animation = "none";
    }
  }
}
