<div class="item-container" [class.featured]="featured" [style.cursor]="editable ? 'default' : 'pointer'" *ngIf="item"
    (click)="itemClicked($event)">
    <div class="header" [class.active]="active">
        <div [style.color]="item.title.color" class="title">{{item.title.content}}</div>
        <div [style.color]="item.subtitle.color" class="subtitle">{{item.subtitle.content}}</div>
        <button (mouseenter)="this.buttonIsHovered.next(true)" (mouseleave)="this.buttonIsHovered.next(false)"
            [style.color]="buttonTextColor" [style.backgroundColor]="buttonBackgroundColor">Click to see
            project</button>
    </div>
    <picture class="thumbnail desktop">
        <img loading="lazy" [src]="item.thumbnail.desktop" onerror="this.src = '../../../assets/images/default.jpg'">
    </picture>
    <picture class="thumbnail mobile">
        <img loading="lazy" [src]="item.thumbnail.mobile" onerror="this.src = '../../../assets/images/default.jpg'">
    </picture>
    <div class="edit-container" *ngIf="editable">
        <button class="edit-btn" (click)="editItem($event)">Edit</button>
        <button class="delete-btn" (click)="deleteItem($event)">Delete</button>
    </div>
</div>