import { AfterViewInit, Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { FromEventTarget } from 'rxjs/internal/observable/fromEvent';

@Injectable({
  providedIn: 'root'
})
export class CssService implements AfterViewInit {

  scrollContainer?: HTMLElement | null;
  scroll$: Observable<Event> | undefined;
  scrollTypeOverlay = new BehaviorSubject<boolean>(false);
  lastScrollTop = 0;

  constructor() { }

  ngAfterViewInit() {
  }

  setScrollObservable(): void {
    this.scrollContainer = document.getElementById("routerWrapper");
    this.scroll$ = fromEvent(this.scrollContainer as FromEventTarget<Event>, 'scroll');
  }

  handleSnapping(): void {
    let scrollTop = this.scrollContainer?.scrollTop;
    if (scrollTop && !this.lastScrollTop) {
      this.scrollContainer?.scroll(0, 80);
      this.scrollContainer?.classList.add("noScroll");
      setTimeout(() => {
        this.scrollContainer?.classList.remove("noScroll");
      }, 200)
    }
    this.lastScrollTop = scrollTop ? scrollTop : 0;
  }

  getScrollTop(): number | undefined {
    return this.scrollContainer?.scrollTop;
  }

  setScrollTypeOverlay(overlay: boolean): void {
    this.scrollTypeOverlay.next(overlay);
  }

  setVirtualHeight(): void {
    document.body.style.setProperty('--virtual-height', `${window.innerHeight}px`);
  }
}
