import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FooterComponent } from './footer/footer.component';
import { ItemComponent } from './item/item.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';

const components = [
    FooterComponent,
    ItemComponent,
]

const pipes = [
    SafeUrlPipe,
]

@NgModule({
    declarations: [
        ...components,
        ...pipes,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        ...components,
        ...pipes,
    ]
})
export class SharedModule { }
