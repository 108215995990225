import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { IDesign } from '../interfaces/design.interfcae';

@Injectable({
  providedIn: 'root'
})
export class DesignService {

  private readonly dbPath = '/designs';

  designsRef: AngularFirestoreCollection<IDesign>;

  // mockedDesignes: IDesign[] = [
  //   {
  //     id: 'asdfasdf',
  //     title: 'Title',
  //     subtitle: 'publishing software like Aldus Page including versions of Lorem',
  //     image: 'https://www.designveloper.com/wp-content/uploads/2020/10/UI-UX-BLOG-BANNER-IMAGE.jpg',
  //   },
  //   {
  //     id: 'asdfasdf',
  //     title: 'Title',
  //     subtitle: 'publishing software like Aldus Page including versions of Lorem',
  //     image: 'https://www.designveloper.com/wp-content/uploads/2020/10/UI-UX-BLOG-BANNER-IMAGE.jpg',
  //   },
  //   {
  //     id: 'asdfasdf',
  //     title: 'Title',
  //     subtitle: 'publishing software like Aldus Page including versions of Lorem',
  //     image: 'https://www.designveloper.com/wp-content/uploads/2020/10/UI-UX-BLOG-BANNER-IMAGE.jpg',
  //   },
  //   {
  //     id: 'asdfasdf',
  //     title: 'Title',
  //     subtitle: 'publishing software like Aldus Page including versions of Lorem',
  //     image: 'https://www.designveloper.com/wp-content/uploads/2020/10/UI-UX-BLOG-BANNER-IMAGE.jpg',
  //   },
  //   {
  //     id: 'asdfasdf',
  //     title: 'Title',
  //     subtitle: 'publishing software like Aldus Page including versions of Lorem',
  //     image: 'https://www.designveloper.com/wp-content/uploads/2020/10/UI-UX-BLOG-BANNER-IMAGE.jpg',
  //   },
  // ]

  private selectedDesign$ = new BehaviorSubject<IDesign | null>(null);

  constructor(private db: AngularFirestore) {
    this.designsRef = this.db.collection(this.dbPath);
  }

  getDesignes(): Observable<IDesign[]> {
    return this.designsRef.valueChanges({ idField: 'id' });
  }

  getSelectedDesign(): Observable<IDesign | null> {
    return this.selectedDesign$.asObservable();
  }

  setSelectedDesign(design: IDesign): void {
    this.selectedDesign$.next(design);
  }

  addDesign(design: IDesign) {
    return this.designsRef.add(design);
  }

  updateDesign(design: IDesign, id: string | undefined): Promise<void> {
    return this.designsRef.doc(id).update(design);
  }

  deleteDesign(id: string): Promise<void> {
    return this.designsRef.doc(id).delete();
  }

}
