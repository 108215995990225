export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAiEk1ZN73075TJQ6EijWvGDfoPOiFkNSA",
    authDomain: "guy-shani.firebaseapp.com",
    projectId: "guy-shani",
    storageBucket: "guy-shani.appspot.com",
    messagingSenderId: "331681267254",
    appId: "1:331681267254:web:d3798d7282032b2ef7e297",
    measurementId: "G-F1GNJBXBKT"
  },
  formSpreeUrl: 'https://formspree.io/f/myylwrao'
};
