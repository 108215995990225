import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { fromEvent, timer } from 'rxjs';
import { FromEventTarget } from 'rxjs/internal/observable/fromEvent';
import { debounce } from 'rxjs/operators';
import { CssService } from './services/css.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'guy-portfolio';

  constructor(public cssService: CssService) {
  }

  ngOnInit(): void {
    this.cssService.setVirtualHeight();
    window.addEventListener('resize', this.cssService.setVirtualHeight);
  }
}