import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { IAnimation } from '../interfaces/animation.interfcae';
import { IDesign } from '../interfaces/design.interfcae';

@Injectable({
  providedIn: 'root'
})
export class FeaturedService {

  private readonly dbPath = '/featured';
  // listOfReferences: 

  constructor(private db: AngularFirestore) {
  }

  getFeatured(type: string): Observable<any> {
    // console.log());
    // return this.db.collection('featured').doc('listOfReferences').get();
    return this.db.collection(type, ref => ref.where('featured', '==', true)).valueChanges({ idField: 'id' });
  }

  // addFeatured(item: IDesign | IAnimation) {
  //   return this.designsRef.add(item);
  // }

  // deleteFeatured(id: string): Promise<void> {
  //   return this.designsRef.doc(id).delete();
  // }
}
